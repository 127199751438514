const home = {
  'home.mint.btn': 'Mint',
  'home.nowhitelist.tips': 'User address is not whitelisted',
  'home.success.tips': 'Successfully cast',
  'home.nft.title': 'NFT Description',
  'home.title': 'GridMind',
  'home.sub.title.1': '享受空投權益',
  'home.sub.title.2': 'GridMind Ai鏈，回饋早期對人工智能發展給予關註和支持的有識之士',
  'home.sub.title.3':
    '在GridMind星船航程中，船員是GridMind重要的成員之一，他們緊隨Web3共識，為社區獲取大量的資源和技術，不斷增強規模、能力和成就。',
  'home.sub.title.4':
    'In the journey of the GridMind starship, the crew is an important member of the GridMind, closely following the Web3 consensus to obtain a large amount of resources and technology for the community, constantly enhancing its scale, capabilities, and achievements.',
  'home.sub.title.5': '船員 crew',
}

export default home
