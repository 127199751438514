import { REACT_APP_ENV } from '@/contracts/chains'
import StarshipNFT from '@/contracts/abis/StarshipNFT.json'

interface ConstantChainIdType {
  StarshipNFT_ADDRESS: string
  apiUrl: string
  apiKey: string
}

interface UseConstantType {
  [chainId: number]: ConstantChainIdType
}

export const DEFAULT_CHAINID_ENV: { [env: string]: number } = {
  dev: 80001,
  uat: 80001,
  prd: 1,
}

export const useConstant: { [env: string]: UseConstantType } = {
  dev: {
    80001: {
      StarshipNFT_ADDRESS: '0xB60a81440527B1E99146f0B4FB3e8B92858AAdc2',
      apiKey: '',
      apiUrl: '',
    },
  },
  uat: {
    80001: {
      StarshipNFT_ADDRESS: '',
      apiKey: '',
      apiUrl: '',
    },
  },
  prd: {
    1: {
      StarshipNFT_ADDRESS: '0x9352FBee1717ddE5e20183E5a5d1077B49ee6e4a',
      apiKey: '',
      apiUrl: '',
    },
  },
}

export const StarshipNFT_ABI: any = StarshipNFT

export const DEFAULT_CHAINID: number = DEFAULT_CHAINID_ENV[REACT_APP_ENV]

export const USECONSTANT: UseConstantType = useConstant[REACT_APP_ENV]

export const getActiveChainId = (arr: string[], network: number) => {
  if (network === null) return false
  return arr.some((item) => Number(item) === Number(network))
}
